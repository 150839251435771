
import Vue from 'vue';
import VueResource from 'vue-resource';
import VueAnalytics from 'vue-analytics';
import VueFullScreen from 'vue-simple-fullscreen';

import './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store';

const sub = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
const tag = (sub === 'arte') ? 'UA-3356552-10' : 'UA-3356552-8';

Vue.use(VueResource);
Vue.use(VueFullScreen);
Vue.use(VueAnalytics, {id: tag, router: router});
Vue.config.productionTip = false;

new Vue({ router, store, render: h => h(App) }).$mount('#app');
