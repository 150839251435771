import Vue from 'vue';
import 'vuetify/src/stylus/app.styl';

import Vuetify,
{
    VApp,
    VBtn,
    VCard,
    VDialog,
    VFadeTransition,
    VFooter,
    VHover,
    VIcon,
    VImg,
    VNavigationDrawer,
    VToolbar

} from 'vuetify/lib';

import { Ripple, Resize } from 'vuetify/lib/directives';

Vue.use(Vuetify,
    {
        components:
        {
            VApp,
            VBtn,
            VCard,
            VDialog,
            VFadeTransition,
            VFooter,
            VHover,
            VIcon,
            VImg,
            VNavigationDrawer,
            VToolbar
        },
        directives:
        {
            Ripple,
            Resize
        },
        iconfont: 'mdi'
    });
