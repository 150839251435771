import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export default new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'catalog',
            component: () => import(/* webpackChunkName: "catalog-page" */ './views/CatalogPage.vue')
        },
        {
            path: '/document/:codetype/:code',
            name: 'document',
            component: () => import(/* webpackChunkName: "document-page" */ './views/DocumentPage.vue')
        },
        {
            path: '/front',
            name: 'front',
            component: () => import(/* webpackChunkName: "front-page" */ './views/FrontPage.vue')
        },
        { path: '*', redirect: '/' }
    ]
});
