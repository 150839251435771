import Vue from 'vue';
import Vuex from 'vuex';
//import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store(
    {
        //plugins: [createPersistedState()],
        state:
        {
            books:
            [
                {
                    'code': '9788026203216',
                    'isbn' : '80-7178-747-7',
                    'name': 'Adolescence',
                    'author': 'Macek, Petr',
                    'access': 'library',
                    'year': 2003
                },
                {
                    'code': '9788073679453',
                    'isbn' : '978-80-7367-422-9',
                    'name': 'Filosofická antropologie',
                    'author': 'Sokol, Jan',
                    'access': 'library',
                    'year': 2008
                },
                {
                    'code': '9788026202547',
                    'isbn' : '80-7178-748-5',
                    'name': 'Globalizace',
                    'author': 'Mezřický, Václav',
                    'access': 'library',
                    'year': 2003
                },
                {
                    'code': '9788026202820',
                    'isbn' : '978-80-7367-709-1',
                    'name': 'Interkulturní psychologie',
                    'author': 'Průcha, Jan',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202639',
                    'isbn' : '978-80-7367-784-8',
                    'name': 'Kvalita života dětí a didaktika',
                    'author': 'Lukášová, Hana',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202738',
                    'isbn' : '978-80-7367-313-0',
                    'name': 'Kvalitativní výzkum v pedagogických vědách',
                    'author': 'Švaříček, Roman, Šeďová, Klára',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202387',
                    'isbn' : '978-80-262-0771-9',
                    'name': 'Masová média',
                    'author': 'Jirák, Jan, Köpplová, Barbara',
                    'access': 'library',
                    'year': 2015
                },
                {
                    'code': '9788026202325',
                    'isbn' : '978-80-7367-502-8',
                    'name': 'Metody a řízení sociální práce',
                    'author': 'Matoušek, Oldřich a kol.',
                    'access': 'library',
                    'year': 2008
                },
                {
                    'code': '9788026202608',
                    'isbn' : '978-80-7367-683-4',
                    'name': 'Metody výzkumu médií',
                    'author': 'Trampota, Tomáš, Vojtěchovská, Martina',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202592',
                    'isbn' : '978-80-7367-540-0',
                    'name': 'Mezinárodní bezpečnost v době globalizace',
                    'author': 'Eichler, Jan',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026201274',
                    'isbn' : '978-80-262-0128-1',
                    'name': 'Mládež a delikvence',
                    'author': 'Matoušek, Oldřich, Matoušková, Andrea',
                    'access': 'library',
                    'year': 2011
                },
                {
                    'code': '9788026202790',
                    'isbn' : '978-80-7367-503-5',
                    'name': 'Moderní pedagogika',
                    'author': 'Průcha, Jan',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026201892',
                    'isbn' : '978-80-262-0419-0',
                    'name': 'Návykové chování a závislost',
                    'author': 'Nešpor, Karel',
                    'access': 'library',
                    'year': 2011
                },
                {
                    'code': '9788026202332',
                    'isbn' : '80-7178-554-7',
                    'name': 'Neuropsychologie',
                    'author': 'Kulišťák, Petr',
                    'access': 'library',
                    'year': 2003
                },
                {
                    'code': '9788026203186',
                    'isbn' : '978-80-7367-558-5',
                    'name': 'Poruchy osobnosti',
                    'author': 'Praško, Ján a kol.',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788073679767',
                    'isbn' : '978-80-7367-975-0',
                    'name': 'Pozitivní psychologie',
                    'author': 'Křivohlavý, Jaro',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026204275',
                    'isbn' : '978-80-262-0428-2',
                    'name': 'Psychologie dítěte',
                    'author': 'Piaget, Jean, Inhelderová, Bärbel',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202356',
                    'isbn' : '978-80-7367-387-1',
                    'name': 'Psychologie komunikace',
                    'author': 'Vybíral, Zbyněk',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026203247',
                    'isbn' : '978-80-7367-312-3',
                    'name': 'Psychologie náboženství a spirituality',
                    'author': 'Říčan, Pavel',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202486',
                    'isbn' : '978-80-7367-627-8',
                    'name': 'Psychologie pro učitelky MŠ',
                    'author': 'Mertin, Vácalv, Gillernová, Ilona',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202530',
                    'isbn' : '978-80-7367-250-8',
                    'name': 'Psychologie rodiny',
                    'author': 'Sobotková, Irena',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202745',
                    'isbn' : '80-7178-802-3',
                    'name': 'Psychopatologie pro pomáhající profese',
                    'author': 'Vágnerová, Marie',
                    'access': 'library',
                    'year': 2004
                },
                {
                    'code': '9788073679552',
                    'isbn' : '80-7178-926-7',
                    'name': 'Slovník mediální komunikace',
                    'author': 'Reifová, Irena a kolektiv',
                    'access': 'library',
                    'year': 2004
                },
                {
                    'code': '9788026202349',
                    'isbn' : '978-80-7367-818-0',
                    'name': 'Sociální práce v praxi',
                    'author': 'Matoušek, Oldřich, Kodymová, Pavla, Koláčková, Jana',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202875',
                    'isbn' : '978-80-262-0434-3',
                    'name': 'Sociologie výchovy a školy',
                    'author': 'Havlík, Radomír, Koťa, Jaroslav',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202301',
                    'isbn' : '978-80-7367-817-3',
                    'name': 'Teorie a náprava vývojových poruch učení a chování',
                    'author': 'Pokorná, Věra',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026201359',
                    'isbn' : '978-80-262-0291-2',
                    'name': 'Úvod do filosofie',
                    'author': 'Anzenbacher, Arno',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202295',
                    'isbn' : '978-80-7367-815-9',
                    'name': 'Úvod do metodologie psychologického výzkumu',
                    'author': 'Ferjenčík, Ján',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202554',
                    'isbn' : '978-80-7367-644-5',
                    'name': 'Úvod do sociologie',
                    'author': 'Jandourek, Jan',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026202424',
                    'isbn' : '978-80-7367-773-2',
                    'name': 'Vývojové poruchy učení v dětství a dospělosti',
                    'author': 'Pokorná, Věra',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026203230',
                    'isbn' : '80-7178-290-4',
                    'name': 'Vzdělávání a školství ve světě',
                    'author': 'Průcha, Jan',
                    'access': 'library',
                    'year': 1999
                },
                {
                    'code': '9788026202769',
                    'isbn' : '978-80-7367-679-7',
                    'name': 'Základy inkluzivní pedagogiky',
                    'author': 'Lechta, Viktor',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202622',
                    'isbn' : '978-80-7367-778-7',
                    'name': 'Základy pedagogicko-psychologického výzkumu pro studenty učitelství',
                    'author': 'Skutil, Martin a kol.',
                    'access': 'library',
                    'year': 2011
                },
                {
                    'code': '9788026201441',
                    'isbn' : '978-80-262-0440-4',
                    'name': 'Základy psychologie emocí',
                    'author': 'Stuchlíková, Iva',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202271',        // 978-80-7367-706-0
                    'isbn' : '978-80-262-0227-1',
                    'name': 'Psychologická diagnostika dospělých',
                    'author': 'Svoboda, Mojmír',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202370',        // 978-80-7367-310-9
                    'isbn' : '978-80-262-0237-0',
                    'name': 'Sociální služby',
                    'author': 'Matoušek, Oldřich',
                    'access': 'library',
                    'year': 2007
                },
                {
                    'code': '9788026202578',        // 978-80-7367-383-3
                    'isbn' : '978-80-262-0257-8',
                    'name': 'Základy sociální pedagogiky',
                    'author': 'Kraus, Blahoslav',
                    'access': 'library',
                    'year': 2008
                },
                {
                    'code': '9788026202653',        // 978-80-7367-873-9
                    'isbn' : '978-80-262-0265-3',
                    'name': 'Tělesné postižení jako fenomén i životní realita',
                    'author': 'Novosad, Libor',
                    'access': 'library',
                    'year': 2011
                },
                {
                    'code': '9788026202677',        // 978-80-262-0084-0
                    'isbn' : '978-80-262-0267-7',
                    'name': 'Sluchové postižení - Úvod do surdopedie',
                    'author': 'Horáková, Radka',
                    'access': 'library',
                    'year': 2012
                },
                {
                    'code': '9788026202776',        // 978-80-7367-682-7
                    'isbn' : '978-80-262-0277-6',
                    'name': 'Současná psychoterapie',
                    'author': 'Vybíral, Zbyněk, Roubal, Jan',
                    'access': 'library',
                    'year': 2010
                },
                {
                    'code': '9788026202806',        // 80-7367-091-7
                    'isbn' : '978-80-262-0280-6',
                    'name': 'Poruchy autistického spektra',
                    'author': 'Thorová, Kateřina',
                    'access': 'library',
                    'year': 2006
                },
                {
                    'code': '9788026203094',
                    'isbn' : '978-80-262-0309-4',
                    'name': 'Hraniční porucha osobnosti',
                    'author': 'Röhr, Heinz-Peter',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026203346',        // 80-7178-803-1
                    'isbn' : '978-80-262-0334-6',
                    'name': 'Stručný psychologický slovník',
                    'author': 'Hartl, Pavel',
                    'access': 'library',
                    'year': 2004
                },
                {
                    'code': '9788026203834',        // 978-80-7367-592-9
                    'isbn' : '978-80-262-0383-4',
                    'name': 'Úvod do veřejného zdravotnictví',
                    'author': 'Janečková, Hana, Hnilicová, Helena',
                    'access': 'library',
                    'year': 2009
                },
                {
                    'code': '9788026209553',
                    'isbn' : '978-80-262-0955-3',
                    'name': 'Závislost - Jak jí porozumět a jak ji překonat',
                    'author': 'Röhr, Heinz-Peter',
                    'access': 'library',
                    'year': 2015
                },
                {
                    'code': '9788073679521',
                    'isbn' : '978-80-7367-952-1',
                    'name': 'Cesta životem - Vývojová psychologie',
                    'author': 'Říčan, Pavel',
                    'access': 'library',
                    'year': 2006
                },
                {
                    "code": "9788026201106",
                    "isbn": "978-80-262-0110-6",
                    "name": "Srovnávací pedagogika",
                    "author": "Prùcha, Jan",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026201144",
                    "isbn": "978-80-262-0114-4",
                    "name": "Sebepoznání",
                    "author": "Kuneš",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026201434",
                    "isbn": "978-80-262-0143-4",
                    "name": "Psychometrika",
                    "author": "Urbánek; Denglerová; Širùèek",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026202288",        // 978-80-7367-710-7
                    "isbn": "978-80-262-0228-8",
                    "name": "Dìtská psychoterapie",
                    "author": "Langmeier, Josef; Balcar, Kare",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202363",        // 80-7367-154-9
                    "isbn": "978-80-262-0236-3",
                    "name": "Psychopatologie a psychiatrie",
                    "author": "Svoboda; Èešková; Kuèerová",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202448",        // 80-7178-997-6
                    "isbn": "978-80-262-0244-8",
                    "name": "Úzkostné poruchy",
                    "author": "Praško, Ján",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202479",        // 80-7178-700-0
                    "isbn": "978-80-262-0247-9",
                    "name": "Psychiatrie",
                    "author": "Malá, Eva; Pavlovský, Pavel",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026202509",        // 978-80-7367-856-2
                    "isbn": "978-80-262-0250-9",
                    "name": "Úvod do transgeneraèní psychologie rodiny",
                    "author": "Tóthová, Jana",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202813",        // 978-80-7367-646-9
                    "isbn": "978-80-262-0281-3",
                    "name": "Manželská a párová terapie",
                    "author": "Kratochvíl, Stanislav",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202868",
                    "isbn": "978-80-262-0286-8",
                    "name": "Konflikty mezi lidmi",
                    "author": "Køivohlavý, Jaro",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202882",
                    "isbn": "978-80-262-0288-2",
                    "name": "Domácí násilí",
                    "author": "Ševèík, Drahomír; Špatenková,",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026203049",
                    "isbn": "978-80-262-0304-9",
                    "name": "Chronická únava",
                    "author": "Praško, Ján; Adamcová, Katarín",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026203148",        // 80-7178-740-X
                    "isbn": "978-80-262-0314-8",
                    "name": "Lékaøská psychologie",
                    "author": "Vymìtal",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026203179",        // 978-80-262-0212-7
                    "isbn": "978-80-262-0317-9",
                    "name": "Krizová intervence",
                    "author": "Vodáèková, Daniela",
                    "access": "library",
                    "year": 2012
                },
                {
                    "code": "9788026202455",        // 978-80-7367-432-8
                    "isbn": "978-80-262-0245-5",
                    "name": "Antropologie",
                    "author": "Soukup, Václav",
                    "access": "library",
                    "year": 2011
                },
                {
                    "code": "9788026205531",
                    "isbn": "978-80-262-0553-1",
                    "name": "Psychologie C. G. Junga",
                    "author": "Jacobi, Jolande",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026206538",
                    "isbn": "978-80-262-0653-8",
                    "name": "Nedostateèný pocit vlastní hodnoty",
                    "author": "Röhr, Heinz-Peter",
                    "access": "library",
                    "year": 2014
                },
                {
                    "code": "9788026208433",
                    "isbn": "978-80-262-0843-3",
                    "name": "Sebeovládání",
                    "author": "Nešpor, Karel",
                    "access": "library",
                    "year": 2015
                },
                {
                    "code": "9788026210603",
                    "isbn": "978-80-262-1060-3",
                    "name": "Psychologie davu",
                    "author": "Le Bon, Gustave",
                    "access": "library",
                    "year": 2016
                },
              
                // new
                {
                    "code": "9788026201922",
                    "isbn": "978-80-262-0192-2",
                    "name": "Biologie pro psychology a pedagogy",
                    "author": "Šmarda, Jan",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026203278",
                    "isbn": "978-80-262-0327-8",
                    "name": "Didaktika ošetřovatelství",
                    "author": "Kuberová, Helena",
                    "access": "library",
                    "year": 2010
                },
                {
                    "code": "9788026211334",
                    "isbn": "978-80-262-1133-4",
                    "name": "Jak se stát dobrým psychoterapeutem",
                    "author": "Vybíral, Zbyněk",
                    "access": "library",
                    "year": 2016
                },
                {
                    "code": "9788026202561",
                    "isbn": "978-80-262-0256-1",
                    "name": "Malé dějiny lingvistiky ",
                    "author": "Černý, Jiří",
                    "access": "library",
                    "year": 2005
                },
                {
                    "code": "9788026216827",
                    "isbn": "978-80-262-1682-7",
                    "name": "Mýty a fakta o autismu",
                    "author": "Hrdlička, Michal",
                    "access": "library",
                    "year": 2020
                },
                {
                    "code": "9788026202516",
                    "isbn": "978-80-262-0251-6",
                    "name": "Náboženství v dialogu",
                    "author": "Štampach, Ivan",
                    "access": "library",
                    "year": 1998
                },
                {
                    "code": "9788026214489",
                    "isbn": "978-80-262-1448-9",
                    "name": "Otázky soudobé psychoanalýzy",
                    "author": "Vavrda, Vladimír",
                    "access": "library",
                    "year": 2019
                  },
                {
                    "code": "9788026219118",
                    "isbn": "978-80-262-1911-8",
                    "name": "Proč volíme psychopaty",
                    "author": "Eddy, Bill",
                    "access": "library",
                    "year": 2022
                },
                {
                    "code": "9788026202431",
                    "isbn": "978-80-262-0243-1",
                    "name": "Sémiotika",
                    "author": "Holeš, Jan; Černý, Jiří",
                    "access": "library",
                    "year": 2004
                },
                {
                    "code": "9788026201137",
                    "isbn": "978-80-262-0113-7",
                    "name": "Sociologie zločinu",
                    "author": "Jandourek, Jan",
                    "access": "library",
                    "year": 2011
                },
                {
                    "code": "9788026202660",
                    "isbn": "978-80-262-0266-0",
                    "name": "Styly a strategie učení ve výuce cizích jazyků",
                    "author": "Lojová, Gabriela; Vlčková, Kateřina",
                    "access": "library",
                    "year": 2011
                },
                {
                    "code": "9788026203223",
                    "isbn": "978-80-262-0322-3",
                    "name": "Turistika a sporty v přírodě",
                    "author": "Neuman, Jan a kol.",
                    "access": "library",
                    "year": 2000
                },
                {
                    "code": "9788026205760",
                    "isbn": "978-80-262-0576-0",
                    "name": "Týmový koučink",
                    "author": "Mohauptová, Eva",
                    "access": "library",
                    "year": 2013
                },
                {
                    "code": "9788026203162",
                    "isbn": "978-80-262-0316-2",
                    "name": "Úvod do teorie a metodologie sociální politiky",
                    "author": "Tomeš, Igor",
                    "access": "library",
                    "year": 2010
                },
                {
                    "code": "9788026204428",
                    "isbn": "978-80-262-0442-8",
                    "name": "Základy sociální psychologie",
                    "author": "Hayesová, Nicky ",
                    "access": "library",
                    "year": 2013
                }
              

            ],
            fullSearch: '',
            viewMode: 'grid',
            totalPages: 0,
            currentPage: 1,
            pageScale: 1,
            userToken: ''
        },
        getters:
        {
            getUserToken: function(state)
            {
                return state.userToken || localStorage.getItem('ELIB_USER_TOKEN') || '';
            },
            getBookList: function(state)
            {
                return state.books;
            }
        },
        actions:
        {
            getBooks: function({ commit, state }, options)
            {
                return new Promise(function(resolve, reject)
                {
                    const books = state.books;

                    const sorted = books.sort(function(a, b)
                    {
                        return a.name.localeCompare(b.name);
                    });
                    const filtered = sorted.filter(function(book)
                    {
                        let found = 1;
                        if (options.search)
                        { // full search
                            let bookName = book.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            let bookAuthor = book.author.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            let search = options.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            let re = new RegExp(search, 'i');
                            let resultName = bookName.search(re) !== -1;
                            let resultAuthor = bookAuthor.search(re) !== -1;
                            return resultName || resultAuthor;
                        }
                        return found;
                    });
                    resolve(filtered);
                });
            }
        },
        mutations:
        {
            setUserToken: function(state, value)
            {
                state.userToken = value;
                localStorage.setItem('ELIB_USER_TOKEN', value);
            },
            setFullSearch: function(state, value)
            {
                state.fullSearch = value.toString();
            },
            setViewMode: function(state, value)
            {
                state.viewMode = value.toString();
            },
            setTotalPages: function(state, value)
            {
                state.totalPages = value;
            },
            setCurrentPage: function(state, value)
            {
                state.currentPage = value;
            },
            setPageScale: function(state, value)
            {
                state.pageScale = value;
            },
        }
    });
